import axios from "axios";
import { toast } from "react-toastify";
import { configAuth } from "../Utils/headersConfig";

export const getCurrentOrgChart = async (company_id: string) => {
  return await axios
    .get(
      process.env.REACT_APP_API_BACKEND +
        "/api/v1" +
        "/organigrama/" +
        company_id,
      configAuth()
    )
    .catch((error) => {
      toast.error("Ha habido un problema, intentelo más tarde");
    });
};

export const getHistoricOrgCharts = async (company_id: string) => {
  return await axios
    .get(
      process.env.REACT_APP_API_BACKEND +
        "/api/v1" +
        "/organigrama/history/" +
        company_id,
      configAuth()
    )
    .then((response) => response.data)
    .catch((error) => {
      toast.error("Ha habido un problema, intentelo más tarde");
    });
};

export const getHistoricByMongoId = async (
  id_mongo: string,
  id_company: string
) => {
  return await axios
    .get(
      process.env.REACT_APP_API_BACKEND +
        "/api/v1" +
        "/organigramanew/new/history/" +
        id_mongo +
        "?id_company=" +
        id_company,
      configAuth()
    )
    .then((res) => res.data)
    .catch((error) => {
      toast.error("Ha habido un problema, intentelo más tarde");
    });
};

export const getDraftsOrgCharts = async (company_id: string) => {
  return await axios
    .get(
      process.env.REACT_APP_API_BACKEND +
        "/api/v1" +
        "/organigramanew/new/template?id_company=" +
        company_id,
      configAuth()
    )
    .then((res) => res.data)
    .catch((error) => {
      toast.error("Ha habido un problema, intentelo más tarde");
    });
};

export const createNewDraft = async (company_id: string) => {
  const body = {};

  return await axios
    .post(
      process.env.REACT_APP_API_BACKEND +
        "/api/v1" +
        "/organigrama/?id_company=" +
        company_id,
      body,
      configAuth()
    )
    .catch((error) => {
      toast.error("Ha habido un problema, intentelo más tarde");
    });
};

export const createFromDraft = async (draft: any) => {
  const org = await getDraftsOrgChart(draft.mongo_id, draft.id_company);

  let id_mongo = draft.mongo_id;
  let id_company = draft.id_company;

  return await axios
    .post(
      process.env.REACT_APP_API_BACKEND +
        "/api/v1/" +
        `organigramanew/new/useTemplate/${id_mongo}?id_company=${id_company}`,
      org.data,
      configAuth()
    )
    .catch((error) => {
      toast.error("Ha habido un problema, intentelo más tarde");
    });
};

export const createFromLastPublished = async (id_company: string) => {
  const body = {};

  return await axios
    .post(
      process.env.REACT_APP_API_BACKEND +
        "/api/v1" +
        "/organigramanew/new/template?id_company=" +
        id_company,
      body,
      configAuth()
    )
    .catch((error) => {
      toast.error("Ha habido un problema, intentelo más tarde");
    });
};

export const getDraftsOrgChart = async (
  id_mongo: string,
  id_company: number
) => {
  let url = `/api/v1/organigramanew/new/template/${id_mongo}?id_company=${id_company}`;
  return await axios
    .get(process.env.REACT_APP_API_BACKEND + url, configAuth())
    .then((res) => res.data);
};

export const copyBranch = async (
  id_employee: number,
  id_append: number,
  order: number | null = null,
  id_company: string,
  id_mongo: string
) => {
  const body = {};

  // Construir la URL con el parámetro order condicional
  const url =
    `${process.env.REACT_APP_API_BACKEND}/api/v1/organigramanew/new/branch/${id_mongo}?id_employee=${id_employee}&id_append=${id_append}&id_company=${id_company}` +
    (order !== null ? `&order=${order}` : "");

  return await axios.post(url, body, configAuth()).catch((error) => {
    toast.error("Ha habido un problema, inténtelo más tarde");
  });
};

export const deleteBranch = async (
  manager_id: number,
  company_id: string,
  id_mongo: string
) => {
  return await axios
    .delete(
      process.env.REACT_APP_API_BACKEND +
        "/api/v1" +
        `/organigramanew/new/branch/${id_mongo}?id_manager=` +
        manager_id +
        "&id_company=" +
        company_id,
      configAuth()
    )
    .catch((error) => {
      toast.error("Ha habido un problema, intentelo más tarde");
    });
};

export const publishDraft = async (company_id: any) => {
  const body = {};

  return await axios.post(
    process.env.REACT_APP_API_BACKEND +
      "/api/v1" +
      "/organigramanew/new/publish" +
      "?id_company=" +
      company_id,
    body,
    configAuth()
  );
};

export const publishDraftDate = async (id_mongo: string, date: any) => {
  const body = {};

  return await axios
    .post(
      process.env.REACT_APP_API_BACKEND +
        "/api/v1" +
        "/publish" +
        "?id_mongo=" +
        id_mongo +
        "&fecha_hora=" +
        date,
      body,
      configAuth()
    )
    .catch((error) => {
      toast.error("Ha habido un problema, intentelo más tarde");
    });
};

export const getScheduledDraft = async () => {
  return await axios.get(
    process.env.REACT_APP_API_BACKEND + "/api/v1" + "/publish/",
    configAuth()
  );
};

export const removeScheduledDraft = async (id: number) => {
  return await axios.delete(
    process.env.REACT_APP_API_BACKEND + "/api/v1" + "/publish/?id=" + id,
    configAuth()
  );
};
export const removeDraft = async (mongo_id: string, to_del: boolean) => {
  return await axios
    .delete(
      process.env.REACT_APP_API_BACKEND +
        "/api/v1" +
        "/organigrama/?id_mongo=" +
        mongo_id +
        "&to_del=" +
        to_del,
      configAuth()
    )
    .catch((error) => {
      toast.error("Ha habido un problema, intentelo más tarde");
    });
};

export const getDraftOrgDepartment = async (
  mongo_id: string,
  departament_id: number,
  company_id: number
) => {
  const body = {};

  return await axios
    .get(
      process.env.REACT_APP_API_BACKEND +
        "/api/v1" +
        "/organigramanew/new/template/department/" +
        departament_id +
        "?id_company=" +
        company_id,
      configAuth()
    )
    .catch((error) => {
      toast.error("Ha habido un problema, intentelo más tarde");
    });
};

export const getCurrentOrgDepartment = async (
  departament_id: number,
  company_id: number
) => {
  return await axios
    .get(
      process.env.REACT_APP_API_BACKEND +
        "/api/v1" +
        "/organigramanew/new/department/" +
        departament_id +
        "?id_company=" +
        company_id,
      configAuth()
    )
    .catch((error) => {
      toast.error("Ha habido un problema, intentelo más tarde");
    });
};

export const getCurrentOrgTags = async (tag_id: number, company_id: number) => {
  return await axios
    .get(
      process.env.REACT_APP_API_BACKEND +
        "/api/v1" +
        "/organigrama/tag/" +
        "?tag_id=" +
        tag_id +
        "&id_company=" +
        company_id,
      configAuth()
    )
    .catch((error) => {
      if (error.status === 404) {
        toast.error("No hay empleados para la etiqueta indicada");
      } else {
        toast.error("Ha habido un problema, intentelo más tarde");
      }
    });
};

export const getDraftOrgTags = async (tag_id: number, company_id: number) => {
  return await axios
    .get(
      process.env.REACT_APP_API_BACKEND +
        "/api/v1" +
        "/organigramanew/new/template/tag/" +
        tag_id +
        "?id_company=" +
        company_id,
      configAuth()
    )
    .catch((error) => {
      toast.error("Ha habido un problema, intentelo más tarde");
    });
};
