import { SyntheticEvent, useEffect, useState } from "react";
import * as yup from "yup";
import { useIsMounted } from "../../../../hooks/useIsMounted";
import { ICompany } from "../../../../interface/services";
import { getAllCompany } from "../../../../Services/CompanyService";
import { AedasLoading } from "../../../Shared/AedasLoading/AedasLoading";
import { DataGridEditabled } from "../../../Shared/DataGridEditabled/DataGridEditabled";
import { TableCompany } from "./TableCompany";

export let schema = yup.object().shape({
  name: yup.string().required(),
});

export const Company = () => {
  const isMounted = useIsMounted();
  const [isLoading, setIsLoading] = useState(true);
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const tableCompany = new TableCompany(setIsLoading);

  useEffect(() => {
    getAllCompany()
      .then((response: ICompany[]) => {
        isMounted && setCompanies(response);
      })
      .then(() => setIsLoading(false));
  }, [isLoading]);

  return (
    <>
      {isLoading ? (
        <AedasLoading />
      ) : (
        <DataGridEditabled
          columns={tableCompany.table}
          handleSave={(e: SyntheticEvent) => {
            tableCompany.handleSave(e);
          }}
          rows={companies}
          showHeader={true}
          singleIcon={true}
          showAddIcon={true}
          title={""}
        ></DataGridEditabled>
      )}
    </>
  );
};
