/**
 * Funcion que ordena los primerops descendientes de un organigrama
 * @param child Organigrama a renderizar.
 */
export const sortChild = (child: any) => {
  child.children?.sort((a: any, b: any) => {
    return parseInt(a.layer) < parseInt(b.layer) ? -1 : 1;
  });
};
/**
 * Funcion que ordena los descendientes  en un organigrama
 * @param child Organigrama que renderizas
 * @returns Organigrama con los descendentes organizados por rango
 */
export const getSortedChildren = (child: any) => {
  sortChild(child);
  child.children?.forEach((child: any) => {
    if (child.children?.length > 0) {
      getSortedChildren(child);
    }
  });
  return child;
};
