import React, { useEffect, useState } from "react";
import "./Positions.css";
import { DeletePositionModal } from "./DeletePositionModal";
import { DeleteLocationModal } from "./DeleteLocationModal";
import { Positions } from "./Positions";
import { Locations } from "./Locations";
import { useSelector } from "react-redux";

export const PositionsAndLocations = () => {
  const [showNewPositionForm, setShowNewPositionForm] = useState(false);

  const [sortedPositions, setSortedPositions] = useState<any[]>();
  const [showModal, setShowModal] = useState(false);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState();
  const [selectedLocation, setSelectedLocation] = useState();
  const [onlySee, setOnlySee] = useState(false);
  const [locationOnlySee, setLocationOnlySee] = useState();
  const positionsAll = useSelector(
    (state: any) => state.positionsReducer.positions
  );

  const locationsAll = useSelector(
    (state: any) => state.locationsReducer.locations
  );

  useEffect(() => {
    setSortedPositions([...positionsAll].sort((a, b) => a.id - b.id));
  }, [positionsAll]);

  const initialValues = {
    name: "",
  };

  return (
    <div className="position_tab">
      {showLocationModal && (
        <DeleteLocationModal
          showLocationModal={showLocationModal}
          setShowLocationModal={setShowLocationModal}
          selectedLocation={selectedLocation}
          locationOnlySee={locationOnlySee}
        ></DeleteLocationModal>
      )}
      {showModal && (
        <DeletePositionModal
          showModal={showModal}
          setShowModal={setShowModal}
          selectedPosition={selectedPosition}
          onlySee={onlySee}
        ></DeletePositionModal>
      )}
      <Positions
        positionsAll={positionsAll}
        setShowNewPositionForm={setShowNewPositionForm}
        showNewPositionForm={showNewPositionForm}
        initialValues={initialValues}
        sortedPositions={sortedPositions}
        setSelectedPosition={setSelectedPosition}
        setOnlySee={setOnlySee}
        setShowModal={setShowModal}
      ></Positions>
      <div className="separador"></div>
      <Locations
        locationsAll={locationsAll}
        setSelectedLocation={setSelectedLocation}
        setShowLocationModal={setShowLocationModal}
        initialValues={initialValues}
        setLocationOnlySee={setLocationOnlySee}
      ></Locations>
    </div>
  );
};
