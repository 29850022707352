import { GridColDef } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import { ICompany } from "../../../../interface/services";
import {
  createCompany,
  removeCompany,
  updateCompany,
} from "../../../../Services/CompanyService";
import { schema } from "./Company";

export class TableCompany {
  constructor(public setIsLoading: any) {}
  table: GridColDef[] = [
    { field: "id", headerName: "ID", width: 9, hide: true },
    {
      field: "name",
      headerName: "Empresa",
      width: 200,
      editable: true,
      flex: 1,
    },
    {
      field: "description",
      headerName: "Descripción",
      width: 200,
      editable: true,
      flex: 1,
    },
    {
      field: "location",
      headerName: "Dirección",
      width: 200,
      editable: true,
      flex: 1,
    },
    {
      field: "position",
      headerName: "Posicion",
      width: 200,
      type: "number",
      editable: true,
      flex: 1,
    },
  ];

  handleCreate = (companies: ICompany[]) => {
    companies.map(async (company: ICompany) => {
      const result = await schema
        .isValid(company)
        .catch((error) => toast.error("La empresa no puede estar vacia"));
      if (result) {
        createCompany(company)
          .then(() => this.setIsLoading(true))
          .catch((error) => console.log(error));
      }
    });
  };
  handleUpdate = (companies: ICompany[]) => {
    companies.map(async (company: ICompany) => {
      const result = await schema
        .isValid(company)
        .catch((error) => toast.error("La empresa no puede estar vacia"));
      if (result) {
        await updateCompany(company)
          .then(() => this.setIsLoading(true))
          .catch((error) => console.log(error));
      }
    });
  };
  handleRemove = (companies: ICompany[]) => {
    companies.map(async (company: ICompany) => {
      const result = await schema
        .isValid(company)
        .catch((error) => toast.error("La empresa no puede estar vacia"));
      if (result) {
        removeCompany(company)
          .then(() => this.setIsLoading(true))
          .catch((error) => console.log(error));
      }
    });
  };
  /**
   * Comprueba cada array filtrado y en cuanto encuentra uno > 0 ejecuta la funcion correspondiente
   * @param data - Arrays filtrados de cada una de estas propiedades
   */
  handleSave = (data: any) => {
    if (data.news.length > 0) this.handleCreate(data.news);
    if (data.edited.length > 0) this.handleUpdate(data.edited);
    if (data.removed.length > 0) this.handleRemove(data.removed);
  };
}
