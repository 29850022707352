import moment from "moment";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { IHistoricOrgChart } from "../../../../../interface/services";
import { getHistoricByMongoId } from "../../../../../Services/OrganizationChartService";
import { AedasLoading } from "../../../../Shared/AedasLoading/AedasLoading";
import { ShowOrganizationChart } from "../../../../Shared/ShowOrganigramaChart/ShowOrganizationChart";
import "./history.css";

interface Params {
  company_id: string;
  id_mongo: string;
  version: string;
}

export function ShowOrgChartHistory() {
  const ids: Params = useParams();
  const [historicOrgChart, setHistoricOrgChart] =
    useState<IHistoricOrgChart | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getHistoricByMongoId(ids.id_mongo, ids.company_id).then(
      (res: IHistoricOrgChart) => {
        setHistoricOrgChart(res);
        setIsLoading(false);
      }
    );
  }, []);

  let version = moment(new Date(ids.version)).format("DD-MM-YYYY - HH:mm");

  return (
    <>
      <h2 className="OrganigramaInfo">Organigrama publicado el {version}</h2>
      {isLoading ? (
        <AedasLoading />
      ) : (
        <ShowOrganizationChart
          orgChart={historicOrgChart}
          setOrgChart={setHistoricOrgChart}
          filter={false}
        />
      )}
    </>
  );
}
