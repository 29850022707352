import axios from "axios";
import { toast } from "react-toastify";
import { configAuth } from "../Utils/headersConfig";

export const getAllTags = async () => {
  return await axios
    .get(process.env.REACT_APP_API_BACKEND + "/api/v1/tag/", configAuth())
    .then((res) => res.data)
    .catch((error) => {
      toast.error("Ha habido un problema, intentelo más tarde");
    });
};

/**
 *
 * @param id_employee
 * @returns
 */
export const getAllTagsEmployee = async (id_employee: number) => {
  return await axios
    .get(
      process.env.REACT_APP_API_BACKEND +
        "/api/v1/employee_tag/employee/tags?id=" +
        id_employee,
      configAuth()
    )
    .then((res) => res.data)
    .catch(() => {});
};

export const createTag = (
  id: number,
  name: string,
  description: string | null,
  is_dep: boolean
) => {
  const body = {
    id,
    name,
    description,
    is_dep,
  };
  return axios.post(
    process.env.REACT_APP_API_BACKEND + "/api/v1/tag/",
    body,
    configAuth()
  );
};

export const updateTag = async (tag: any) => {
  const body = {
    id: tag.id,
    name: tag.name,
    is_dep: tag.is_dep,
    description: tag.description,
  };

  return await axios
    .put(
      process.env.REACT_APP_API_BACKEND + "/api/v1/tag/" + tag.id,
      body,
      configAuth()
    )
    .catch((error) => {
      toast.error("Ha habido un problema, intentelo más tarde");
    });
};

export const removeTag = async (tag: any) => {
  return await axios
    .delete(
      process.env.REACT_APP_API_BACKEND + "/api/v1/tag/" + tag.id,
      configAuth()
    )
    .catch((error) => {
      toast.error("Ha habido un problema, intentelo más tarde");
    });
};
