import { useState } from "react";
import { useParams } from "react-router-dom";
import { Autocomplete, Button, TextField } from "@mui/material";
import { useFormik } from "formik";
import {
  getCurrentOrgDepartment,
  getDraftOrgDepartment,
  getDraftOrgTags,
} from "../../../../Services/OrganizationChartService";
import { getNewOrgTag } from "../../../../Services/NewOrgService";

export function FilterForm(props: any) {
  const {
    tags,
    departments,
    setDraft,
    setFiltered,
    setDepartmentFiltered,
    setIsLoading,
  } = props;
  const [IsLoadingButtom, setIsLoadingButtom] = useState(false);
  const [department, setDepartment] = useState<any>(null);
  const [tag, setTag] = useState<any>(null);

  const params: any = useParams();

  const formik = useFormik({
    initialValues: {
      department,
      tag,
    },
    validationSchema: null,
    onSubmit: (values) => {
      let promises: any = [];

      setIsLoadingButtom(true);
      if (values.tag) {
        if (props.type === "draft") {
          promises.push(
            getDraftOrgTags(values.tag?.id, props.company_id).then((res) =>
              setDraft(res)
            )
          );
        } else if (props.type === "current") {
          promises.push(
            getNewOrgTag(values.tag?.id, props.company_id).then((res) =>
              setDraft(res)
            )
          );
        }
      }

      if (values.department) {
        if (props.type === "draft") {
          setIsLoading(true);
          promises.push(
            getDraftOrgDepartment(
              params.template_id,
              values.department?.id,
              props.company_id
            )
              .then((response: any) => {
                setIsLoading(false);
                setDraft({ data: response.data });
              })
              .then((response: any) => setFiltered(true))
          );
        } else if (props.type === "current") {
          promises.push(
            getCurrentOrgDepartment(values.department?.id, +props.company_id)
              .then((response: any) => {
                setDraft(response.data);
              })
              .then((response: any) => {
                setFiltered(true);
              })
              .then((response: any) =>
                setDepartmentFiltered(values.department?.name)
              )
          );
        }
      }
      props.setShowFilter(false);
      Promise.all(promises)
        .then(() => {
          props.setIsLoading(true);
        })
        .catch((error) => console.error(error));
    },
  });

  return (
    <>
      <form onSubmit={(values) => formik.handleSubmit(values)}>
        {/* INPUT DEPARTAMENTO */}
        <div className="p-4">
          <Autocomplete
            id="department"
            options={departments}
            onChange={(e, value) => {
              formik.setFieldValue("department", value);
            }}
            getOptionLabel={(option: any) => option.name}
            isOptionEqualToValue={(option: any, value: any) =>
              option.id === value.id
            }
            disabled={formik.values.tag ? true : false}
            value={formik.values.department}
            renderInput={(params) => (
              <TextField {...params} label="Departamento" size="small" />
            )}
          />
        </div>
        {/* FIN  INPUT DEPARTAMENTO */}
        {/* INPUT ETIQUETAS */}
        <div className="px-4 pb-4">
          <Autocomplete
            id="tag"
            options={tags}
            onChange={(e, value) => {
              formik.setFieldValue("tag", value);
            }}
            getOptionLabel={(option: any) => option.name}
            isOptionEqualToValue={(option: any, value: any) =>
              option.id === value.id
            }
            disabled={formik.values.department ? true : false}
            value={formik.values.tag}
            renderInput={(params) => (
              <TextField {...params} label="Etiqueta" size="small" />
            )}
          />
        </div>
        {/* FIN INPUT ETIQUETAS */}
        <div className="px-4">
          <Button
            style={{
              color: IsLoadingButtom ? "var(--blue-lis)" : "white",
              backgroundColor: "var(--blue-lis)",
              stroke: "white",
            }}
            fullWidth
            type="submit"
          >
            Aplicar
          </Button>
        </div>
      </form>
    </>
  );
}
