import { useEffect, useState } from "react";
import {
  Route,
  useHistory,
  useLocation,
  Redirect,
  Switch,
} from "react-router-dom";
import * as microsoftTeams from "@microsoft/teams-js";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { useIsAuthenticated, useMsal, MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { validatePermission } from "./Utils/permissionsUtils";
import { loginRequest } from "./authConfig";
import { CustomNavigationClient } from "./Utils/NavigationClient";
import { Login } from "./Components/Static/Login/Login";
import { AedasLoading } from "./Components/Shared/AedasLoading/AedasLoading";
import { Header } from "./Components/Shared/Header/Header";
import { OrganizationChart } from "./Components/Static/OrganizationChartMain/OrganizationChart";
import { ShowDrafts } from "./Components/Static/OrganizationChartMain/ShowDrafts/ShowDrafts";
import { EditDraftOrgChart } from "./Components/Static/OrganizationChartMain/ShowDrafts/EditDraftOrgChart/EditDraftOrgChart";
import { ShowOrgChartHistory } from "./Components/Static/OrganizationChartMain/ShowHistory/History/ShowOrgChartHistory";
import { Tab } from "./Visual/components/Tab/Tab";
import { userLogin } from "./Redux/Actions/User.action";
import { EmployeeManagementMain } from "./Components/Static/EmployeeManagement/EmployeeManagementMain";
import { OrganigramaMainTag } from "./Visual/components/Organigrama/OrgChartTag";
import { HistoryOrganizationChart } from "./Components/Static/OrganizationChartMain/ShowHistory/HistoryOrgChart";
import { IMenu } from "./interface/header";
import { CurrentOrgChart } from "./Components/Static/OrganizationChartMain/ShowCurrent/CurrentOrgChart";
import { loginAzure, loginTeams } from "./Services/AuthService";
import { OrgChartDepartment } from "./Visual/components/Organigrama/OrgChartDepartment";
import { OrgChartCompany } from "./Visual/components/Organigrama/OrganigramaCompany";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { getAllInternalPositions } from "./Services/InternalPosition";
import { getPositions } from "./Redux/Actions/Positions.action";
import { getAllLocations } from "./Services/LocationService";
import { getLocations } from "./Redux/Actions/Locations.action";

type AppProps = {
  pca: IPublicClientApplication;
};

export function App({ pca }: AppProps) {
  // Aplicacion de teams

  const history = useHistory();
  const navigationClient = new CustomNavigationClient(history);
  pca.setNavigationClient(navigationClient);

  return (
    <>
      <ToastContainer autoClose={1500}></ToastContainer>
      <MsalProvider instance={pca}>
        <Router></Router>
      </MsalProvider>
    </>
  );
}

const Router = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const isLogin = useSelector((state: any) => state.userReducer.isLogin);
  const user = useSelector((state: any) => state.userReducer.user);
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();
  const { instance, accounts, inProgress } = useMsal();
  const dispatch = useDispatch();
  const [token, setToken] = useState<boolean>(false);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("aedas_user") as string)?.token) {
      setToken(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("aedas_user")]);

  useEffect(() => {
    getAllInternalPositions().then((response: any) => {
      dispatch(getPositions(response));
    });
    getAllLocations().then((response: any) => {
      dispatch(getLocations(response));
    });
  }, []);

  useEffect(() => {
    if (isLogin) {
      if (
        !validatePermission(
          user ? user.permissions : [],
          "app_organigrama.rrcc.all"
        )
      ) {
        const infoLocation = location.pathname + location.search;

        if (location.pathname.includes("/organigrama/")) {
          history.push(location.pathname);
        } else if (infoLocation.includes("/organigrama?search")) {
          history.push(location.pathname + location.search);
        } else {
          history.push("/organigrama");
        }
      } else {
        if (location.pathname === "/login") {
          history.push("/organigramas");
        } else {
          history.push(location.pathname);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogin]);

  useEffect(() => {
    microsoftTeams.app
      .initialize()
      .then(async () => {
        //Perform Azure AD single sign-on authentication
        microsoftTeams.authentication
          .getAuthToken()
          .then(async (result) => {
            const responseBackend: any = await loginTeams(result).catch(
              (error) => console.log("ERROR")
            );
            let position: string = "Por defecto";

            if (responseBackend) {
              dispatch(
                userLogin(
                  responseBackend.data,
                  responseBackend.data.token,
                  position,
                  true
                )
              );
              localStorage.setItem(
                "aedas_user",
                JSON.stringify(responseBackend.data)
              );
              setIsLoading(false);
            } else {
              toast.error("No tienes permiso");
              history.push("/login");
              setIsLoading(false);
            }
          })

          .catch((error: any) => {
            toast.error(error);
          });
      })
      .catch((error: any) => {
        if (isAuthenticated && inProgress === "none") {
          const request = {
            ...loginRequest,
            account: accounts[0],
          };
          instance
            .acquireTokenSilent(request) // Consigue el token de forma silenciosa sino, te muestra un popup.
            .then(async (response) => {
              const responseBackend: any = await loginAzure(
                response.accessToken
              ).catch((error) => console.log("ERROR"));

              let position: string = "Por defecto";

              if (responseBackend) {
                dispatch(
                  userLogin(
                    responseBackend.data,
                    responseBackend.data.token,
                    position,
                    true
                  )
                );
                localStorage.removeItem("aedas_user");
                localStorage.setItem(
                  "aedas_user",
                  JSON.stringify(responseBackend.data)
                );
                setIsLoading(false);
              } else {
                toast.error("No tienes permiso");
                history.push("/login");
                setIsLoading(false);
              }
            })
            .catch((e) => {
              console.log(e);
            });
        } else if (!isAuthenticated && inProgress === "none") {
          instance
            .loginRedirect(loginRequest)
            .then((response) => {})
            .catch((e) => {
              console.error(e);
            });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, inProgress]);

  const menu: IMenu[] = [
    {
      name: "Organigramas",
      localPath: "/organigramas",
      icon: <AssignmentIndOutlinedIcon />,
      onClick: history.push,
      shortName: "Organigrama",
      canView: validatePermission(
        user ? user.permissions : [],
        "app_organigrama.rrcc.all"
      ),
    },

    {
      name: "Gestión de Empleados",
      localPath: "/gestion-empleados",
      icon: <Brightness7Icon />,
      onClick: history.push,
      shortName: "Gestión Emp.",
      canView: validatePermission(
        user ? user.permissions : [],
        "app_organigrama.rrcc.all"
      ),
    },
  ];
  if (isLoading || !token) {
    return (
      <div className="w-100 h-100 d-flex justify-content-center">
        <AedasLoading></AedasLoading>
      </div>
    );
  }

  return (
    <>
      <div className="row" style={{ height: "100vh", overflowX: "hidden" }}>
        {isLogin &&
        validatePermission(
          user ? user.permissions : [],
          "app_organigrama.rrcc.all"
        ) ? (
          <Header menu={menu} open={isOpen} setIsOpen={setIsOpen}></Header>
        ) : (
          <></>
        )}

        <div
          style={
            isLogin &&
            validatePermission(
              user ? user.permissions : [],
              "app_organigrama.rrcc.all"
            )
              ? {
                  flex: 1,
                  marginLeft: isOpen ? "300px" : "70px",
                  maxWidth: "100vw",
                  height: "100vh",
                  overflowY: "scroll",
                  // overflow: "hidden"
                }
              : {
                  flex: 1,
                  maxWidth: "100vw",
                  height: "100vh",
                  overflowY: "scroll",
                }
          }
          className="w-100"
        >
          <Switch>
            <Route exact path="/login">
              <Login isLogin={isLogin}></Login>
            </Route>

            <PrivateRoute
              auth={isLogin}
              hasPermissions={validatePermission(
                user ? user.permissions : [],
                "app_organigrama.rrcc.all"
              )}
              exact
              path="/"
            >
              {<OrganizationChart></OrganizationChart>}
            </PrivateRoute>

            <PrivateRoute
              auth={isLogin}
              hasPermissions={validatePermission(
                user ? user.permissions : [],
                "app_organigrama.rrcc.all"
              )}
              exact
              path="/organigramas"
            >
              <OrganizationChart />
            </PrivateRoute>

            <PrivateRoute
              auth={isLogin}
              hasPermissions={validatePermission(
                user ? user.permissions : [],
                "app_organigrama.rrcc.all"
              )}
              exact
              path="/organigramas/organigrama-actual/:company_id"
            >
              <CurrentOrgChart></CurrentOrgChart>
            </PrivateRoute>

            <PrivateRoute
              auth={isLogin}
              hasPermissions={validatePermission(
                user ? user.permissions : [],
                "app_organigrama.rrcc.all"
              )}
              exact
              path="/organigramas/borradores/:company_id"
            >
              <ShowDrafts />
            </PrivateRoute>

            <PrivateRoute
              auth={isLogin}
              hasPermissions={validatePermission(
                user ? user.permissions : [],
                "app_organigrama.rrcc.all"
              )}
              exact
              path="/organigramas/borradores/:company_id/:template_id"
            >
              <EditDraftOrgChart />
            </PrivateRoute>

            <PrivateRoute
              auth={isLogin}
              hasPermissions={validatePermission(
                user ? user.permissions : [],
                "app_organigrama.rrcc.all"
              )}
              exact
              path="/organigramas/historial/:company_id"
            >
              <HistoryOrganizationChart />
            </PrivateRoute>

            <PrivateRoute
              auth={isLogin}
              hasPermissions={validatePermission(
                user ? user.permissions : [],
                "app_organigrama.rrcc.all"
              )}
              exact
              path="/organigramas/historial/:company_id/:id_mongo&version=:version"
            >
              <ShowOrgChartHistory />
            </PrivateRoute>

            <Route exact path="/organigrama" component={Tab} />
            <Route exact path="/organigrama/:id" component={OrgChartCompany} />
            <Route
              exact
              path="/organigrama/company/:id/employee/:employee"
              component={OrgChartCompany}
            />

            <Route
              exact
              path="/organigrama/departamento/:id"
              component={OrgChartDepartment}
            />
            <Route
              exact
              path="/organigrama/departamento/:id/:employee"
              component={OrgChartDepartment}
            />

            <Route
              exact
              path="/organigrama/etiqueta/:id"
              component={OrganigramaMainTag}
            />

            <Route
              exact
              path="/organigrama/etiqueta/:id/:employee"
              component={OrganigramaMainTag}
            />

            <Route
              exact
              path="/organigrama/:path/:id/empleado/:id_employee"
              component={OrgChartDepartment}
            />

            <PrivateRoute
              auth={isLogin}
              hasPermissions={validatePermission(
                user ? user.permissions : [],
                "app_organigrama.rrcc.all"
              )}
              exact
              path="/gestion-empleados"
            >
              <EmployeeManagementMain />
            </PrivateRoute>
          </Switch>
        </div>
      </div>
    </>
  );
};

const PrivateRoute = (props: any) => {
  if (props.auth) {
    if (props.hasPermissions) {
      return <Route {...props}></Route>;
    } else {
      return <Redirect to="/organigrama" />;
    }
  } else {
    return <Route {...props}></Route>;
  }
};
