import { useEffect, useState } from "react";
import { getAllEmployee } from "../../../../Services/EmployeeConfig";
import { IEmployee } from "../../../../interface/services";
import { AedasLoading } from "../../AedasLoading/AedasLoading";
import {
  deleteEmployeeTag,
  updateEmployeeTag,
} from "../../../../Services/EmployeeTagService";
import { Button, TextField } from "@mui/material";
import "./editEmployeeTags.css";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { toast } from "react-toastify";

export const EditPeopleTags = ({ employeeTagRow, setModal }: any) => {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [orderNumber, setOrderNumber] = useState<any>([]);
  const [idsChangesOrder, setIdsChangesOrder] = useState<any[]>([]);

  const handleRemoveTag = async (params: any) => {
    await deleteEmployeeTag(params?.row?.tag_id);
    setModal(false);
  };

  //   Comprueba la etiqueta seleccionada y filtra los empleados que tengan esa etiqueta
  useEffect(() => {
    if (loading && employeeTagRow) {
      getAllEmployee()
        .then((resEmployees) => {
          const employeesWithTagSelected = resEmployees.filter(
            (emp: IEmployee) =>
              //Matchea la etiqueta seleccionada con los empleados que la tienen
              employeeTagRow.find((tag: any) => tag.id_employee === emp.id)
          );

          const response = employeesWithTagSelected.map((employee: any) => {
            const id_tag = employeeTagRow.find(
              (e: any) => employee.id === e.id_employee
            );
            const { id } = id_tag;
            return {
              ...employee,
              tag_id: id,
              order: id_tag.order,
            };
          });
          setEmployees(response);
        })
        .then((res) => {
          setLoading(false);
        });
    }
  }, [loading]);

  const asignOrder = (params: any) => {
    const tag = employeeTagRow.find(
      (e: any) => params.row.id === e.id_employee
    );

    tag.order = orderNumber.find(
      (orders: any) => orders.id_employee === params.row.id
    ).order;

    updateEmployeeTag(tag, tag.id);
  };

  if (loading) {
    return <AedasLoading></AedasLoading>;
  }

  const handleAsignOrderAllEmployeeTeam = () => {
    orderNumber.forEach((orderItem: any) => {
      const tag = employeeTagRow.find(
        (e: any) => orderItem.id_employee === e.id_employee
      );
      tag.order = orderItem.order;
      updateEmployeeTag(tag, tag.id);
      setOrderNumber([]);
    });
  };

  const handleCommitItemOrder = (param: any) => {
    const copyObjectData: any[] = structuredClone(idsChangesOrder);
    copyObjectData.push(param.id);
    setIdsChangesOrder(copyObjectData);
    const updatedListOrderNumber = orderNumber.map((item: any) => {
      if (item.id === param.id) {
        item.order = param.value;
      }
      return item;
    });

    setOrderNumber(updatedListOrderNumber);
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 9, hide: true },
    {
      field: "display_name",
      headerName: "Nombre",
      width: 200,
      editable: false,
      flex: 1,
    },
    {
      field: "azure_rol",
      headerName: "Rol",
      width: 200,
      editable: false,
      flex: 1,
    },

    {
      field: "order",
      headerName: "Establece orden",
      renderCell: (params: GridValueGetterParams) => {
        return (
          <TextField
            id="filled-number"
            type="text"
            variant="standard"
            value={params.row.order}
            onChange={(e: any) => {
              const newOrderValue = parseInt(e.target.value);
              params.row.order = newOrderValue;
              const existingIndex = orderNumber.findIndex(
                (order: any) => order.id_employee === params.row.id
              );
              if (existingIndex !== -1) {
                const updatedOrdersList = [...orderNumber];
                updatedOrdersList[existingIndex] = {
                  order: newOrderValue,
                  id_employee: params.row.id,
                };
                setOrderNumber(updatedOrdersList);
              } else {
                const newOrder = {
                  order: newOrderValue,
                  id_employee: params.row.id,
                };
                const updatedOrdersList = [...orderNumber, newOrder];
                setOrderNumber(updatedOrdersList);
              }
            }}
          />
        );
      },
      width: 150,
      flex: 0,
      // editable: true,
    },
    {
      field: "actions",
      headerName: "Acciones",
      renderCell: (params: GridValueGetterParams) => {
        return (
          <>
            <Button
              onClick={(e: any) => {
                handleRemoveTag(params);
              }}
              color="primary"
              className="org_Button"
            >
              Eliminar
            </Button>
            <Button
              onClick={() => {
                setTimeout(() => {
                  asignOrder(params);
                }, 800);
              }}
              color="primary"
              className="org_Button"
            >
              Guardar
            </Button>
          </>
        );
      },
      width: 200,
      flex: 0,
    },
  ];
  return (
    <>
      {employees?.length > 0 ? (
        <div
          style={{
            height: "500px",
            background: "white",
            width: "100%",
            margin: "0 1rem 0 1rem",
          }}
        >
          <DataGrid
            style={{ height: "90%" }}
            rows={employees}
            columns={columns}
            disableSelectionOnClick={false}
            editMode="cell"
            onCellEditCommit={(params: any) => {
              handleCommitItemOrder(params);
            }}
            // getRowId={props.getRowId}
          ></DataGrid>
          <div
            className="d-flex w-full justify-content-end"
            style={{ marginTop: ".5rem" }}
          >
            <Button
              onClick={() => {
                handleAsignOrderAllEmployeeTeam();
              }}
              color="primary"
              className="org_Button p-2"
            >
              Guardar todos
            </Button>
          </div>
        </div>
      ) : (
        <p style={{ color: "var(--aedas-red)" }}>
          No hay ningun empleado asignado a esta etiqueta
        </p>
      )}
    </>
  );
};
