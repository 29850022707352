import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import SaveIcon from "@mui/icons-material/Save";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTableEdit } from "../../../../hooks/useTableEdit";
import "./TableEdit.css";

export function TableEdit(props: any) {
  const {
    handleAdd,
    handleRemove,
    handleSave,
    handlePublish,
    columsEditTable,
    rowsEditTable,
    selectionModel,
    setSelectionModel,
    handleCellEditCommit,
  } = useTableEdit(props);

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer className="d-flex justify-content-between">
        <h1 className="titleTableNormal">{props.title}</h1>
        <div>
          {props.showAddIcon ? (
            <>
              <IconButton
                onClick={() => {
                  handleAdd();
                  typeof props.openModal === "function" && props.openModal();
                }}
              >
                <AddCircleOutlineIcon />
              </IconButton>
            </>
          ) : (
            <></>
          )}
          <IconButton title="Eliminar" onClick={handleRemove}>
            <DeleteIcon></DeleteIcon>
          </IconButton>
          <IconButton title="Guardar" onClick={handleSave}>
            <SaveIcon></SaveIcon>
          </IconButton>

          {/*TODO: DESCOMENTAR CUANDO ESTE EL BACKEND <IconButton title="Publicar" onClick={() => handlePublish()}>
            <AssignmentTurnedInIcon></AssignmentTurnedInIcon>
          </IconButton> */}
        </div>
      </GridToolbarContainer>
    );
  };
  let styleHeight = "100%";
  styleHeight = props.modifiedHeight ? "89%" : "100%";
  return (
    <div className="dataGrid-table" style={{ height: styleHeight }}>
      <DataGrid
        components={{ Toolbar: CustomToolbar }}
        rows={rowsEditTable}
        getRowId={props.getRowId}
        columns={columsEditTable}
        disableSelectionOnClick
        checkboxSelection={!props.onlyEdit}
        onCellEditCommit={handleCellEditCommit}
        onSelectionModelChange={(newSelectionModel: any) => {
          setSelectionModel(newSelectionModel);
        }}
        onCellClick={props.onCellClick}
        selectionModel={selectionModel}
        getRowClassName={(params) => {
          if (params.row.removed) return `redBackgraund`;
          if (params.row.new) return `greenBackgraund`;
          if (params.row.edited) return `orangeBackgraund`;
          if (params.row.publish) return `greenBackgraund`;
          return "";
        }}
      ></DataGrid>
    </div>
  );
}
