import { SyntheticEvent, useEffect, useState } from "react";
import { getAllOrders } from "../../../../Services/orderService";
import { AedasLoading } from "../../../Shared/AedasLoading/AedasLoading";
import { DataGridEditabled } from "../../../Shared/DataGridEditabled/DataGridEditabled";
import { EditOrders } from "../../../Shared/Modals/EditOrders/EditOrders";
import { CustomModal } from "../../../Shared/Modals/ModalCustom/CustomModal";
import { TableOrder } from "./tableOrders";
import { useToggle } from "../../../../hooks/useToggle";

export const Orders = () => {
  const [openModal, setOpenModal] = useToggle();
  const [isLoading, setIsLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const tableOptions = new TableOrder(orders);

  useEffect(() => {
    getAllOrders()
      .then(setOrders)
      .then(() => setIsLoading(false));
  }, [isLoading, openModal]);

  return (
    <>
      {isLoading ? (
        <AedasLoading />
      ) : (
        <>
          <CustomModal
            size="lg"
            title="Creacción"
            show={openModal}
            onChange={setOpenModal}
            body={
              <EditOrders
                showModal={setOpenModal}
                isLoading={() => setIsLoading(!isLoading)}
              ></EditOrders>
            }
          ></CustomModal>

          <DataGridEditabled
            columns={tableOptions.columns}
            rows={orders}
            showHeader={true}
            singleIcon={true}
            showAddIcon={true}
            openModal={setOpenModal}
            handleSave={(e: SyntheticEvent) => {
              setIsLoading(!isLoading);
              tableOptions.handleSave(e);
            }}
            // onCellClick={() => undefined}
          ></DataGridEditabled>
        </>
      )}
    </>
  );
};
