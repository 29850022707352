import axios from "axios";
import { toast } from "react-toastify";
import { configAuth } from "../Utils/headersConfig";

export const getAllEmployeeTags = async () => {
  return await axios
    .get(
      process.env.REACT_APP_API_BACKEND + "/api/v1" + "/employee_tag/",
      configAuth()
    )
    .then((res) => res.data)
    .catch((error) => {
      toast.error("Ha habido un problema, intentelo más tarde");
    });
};

export const getAllEmployeeTagsByID = async (id: any) => {
  return await axios
    .get(
      process.env.REACT_APP_API_BACKEND + "/api/v1" + "/employee_tag/" + id,
      configAuth()
    )
    .then((res) => res.data)
    .catch((error) => {
      toast.error("Ha habido un problema, intentelo más tarde");
    });
};

export const updateEmployeeTag = (body: any, id: any) => {
  axios
    .put(
      process.env.REACT_APP_API_BACKEND + "/api/v1" + "/employee_tag/" + id,
      body,
      configAuth()
    )
    .then((res) => toast.success("Añadido correctamente"))
    .catch((error) => {
      toast.error("Ha habido un problema, intentelo más tarde");
    });
};

export const updateEmployeeTagArray = (body: any) => {
  axios
    .post(
      process.env.REACT_APP_API_BACKEND + "/api/v1" + "/employee_tag/",
      body,
      configAuth()
    )
    .then((res) => toast.success("Añadido correctamente"))
    .catch((error) => {
      toast.error("Ha habido un problema, intentelo más tarde");
    });
};

export const deleteEmployeeTag = async (id: any) => {
  return await axios
    .delete(
      process.env.REACT_APP_API_BACKEND + "/api/v1" + "/employee_tag/" + id,
      configAuth()
    )
    .then(() => toast.success("Eliminado correctamente"))
    .catch((err) => toast.error("Error al Borrar"));
};
