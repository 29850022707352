import axios from "axios";

import { toast } from "react-toastify";
import { ICompany } from "../interface/services";
import { configAuth } from "../Utils/headersConfig";

export const getAllCompany = async () => {
  return await axios
    .get(
      process.env.REACT_APP_API_BACKEND + "/api/v1" + "/company/",
      configAuth()
    )
    .then((res) => res.data);
};

export const getCompanyByID = async (companyID: string) => {
  return await axios
    .get(
      process.env.REACT_APP_API_BACKEND + "/api/v1" + "/company/" + companyID,
      configAuth()
    )
    .then((res) => res.data);
};

export const createCompany = async (company: ICompany) => {
  return await axios
    .post(
      process.env.REACT_APP_API_BACKEND + "/api/v1" + "/company/",
      company,
      configAuth()
    )
    .catch((error) => {
      console.log(error);
    });
};

export const updateCompany = async (company: Partial<ICompany>) => {
  return await axios.put(
    process.env.REACT_APP_API_BACKEND + "/api/v1" + "/company/",
    company,
    configAuth()
  );
};

export const removeCompany = async (company: Partial<ICompany>) => {
  return await axios
    .delete(
      process.env.REACT_APP_API_BACKEND + "/api/v1" + "/company/" + company.id,
      configAuth()
    )
    .catch((error) => {
      if (error.response.status === 404) {
        toast.error(
          "No se puede borrar una empresa que tiene empleados asignados"
        );
        throw "No se puede borrar";
      } else {
        toast.error("Ha habido un problema, intentelo más tarde");
      }
    });
};
