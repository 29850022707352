import axios from "axios";

export const loginAzure = async (token: string) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  return await axios.get(
    process.env.REACT_APP_API_BACKEND + "/api/v1/auth" + "/loginAzure",
    config
  );
};

export const loginTeams = async (token: string) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  return await axios.get(
    process.env.REACT_APP_API_BACKEND + "/api/v1/auth" + "/loginTeams",
    config
  );
};

//PRUEBAS PARA VER SI SE PUEDE BORRAR.
// export const login = async (email: string, password: string) => {
//   const response = await axios
//     .post(process.env.REACT_APP_API_BACKEND + "/api/v1/auth" + "/login", {
//       email: email,
//       password: password,
//     })
//     .catch((error) => {
//       if (error.request.status === 401) {
//         toast.error("Usuario o contraseña incorrecto");
//       } else {
//         toast.error("Ha habido un problema, intentelo más tarde");
//       }
//     });

//   if (!response) {
//     return null;
//   }

//   localStorage.setItem("aedas_user", JSON.stringify(response.data));
//   return JSON.stringify(response.data);
// };
