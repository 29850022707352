import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Button } from "react-bootstrap";
import { Field, Form, Formik } from "formik";
import { useModalOrders } from "../../../../hooks/modals/useModalOrders";
import "./editOrders.css";

export const EditOrders = ({ showModal, isLoading }: any) => {
  const { options, handleSelect, selection, handleSubmit, tags, deparments } =
    useModalOrders(showModal);

  const initialValues = {
    position: "",
    id_type: selection,
    id_object: "",
  };

  return (
    <>
      {/* PREGUNTA QUE QUIERES AÑADIR */}
      <FormControl className="w-50 mx-auto">
        <InputLabel id="label-Select">¿Que quieres añadir?</InputLabel>
        <Select
          labelId="label-Select"
          id="label-Select"
          value={selection}
          label="¿Que quieres añadir?"
          onChange={handleSelect}
        >
          {options.map((op: any) => (
            <MenuItem value={op.value} key={op.value}>
              {op.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {/* FIN PREGUNTA QUE QUIERES AÑADIR */}
      {/* FORMULARIO DE ETIQUETA */}
      {(() => {
        if (selection === 1) {
          return (
            <>
              <h2 className="text-center p-3">Etiquetas</h2>
              <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                {({ values, handleChange }) => (
                  <Form className="text-center d-flex gap-3">
                    <Field
                      as={TextField}
                      style={{ width: "50%" }}
                      id="id_object"
                      label="Etiqueta"
                      name="id_object"
                      select
                      required
                      fullWidth
                      value={values.id_object}
                      onChange={handleChange}
                    >
                      {tags.map((option: any) => (
                        <MenuItem
                          className="text-capitalize"
                          key={option.id}
                          value={option.id}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </Field>
                    <Field
                      as={TextField}
                      id="position"
                      style={{ width: "50%" }}
                      name="position"
                      label="posición"
                      required
                      type={"number"}
                      values={values.position}
                      inputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                        min: 0,
                        max: 100,
                      }}
                      helperText="Ingresa el número correspondiente a su posición"
                    />
                    <Button
                      type="submit"
                      className="save_Button text-center p-0 w-25 d-flex justify-content-center"
                      color="primary"
                    >
                      Guardar
                    </Button>
                  </Form>
                )}
              </Formik>
            </>
          );
        }
      })()}
      {/* FIN FORMULARIO DE ETIQUETA */}
      {/* FORMULARIO DE DEPARTAMENTO */}
      {(() => {
        if (selection === 0) {
          return (
            <>
              <h2 className="text-center p-3">Departamento</h2>
              <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                {({ values, handleChange }) => (
                  <Form className="text-center d-flex gap-3">
                    <Field
                      as={TextField}
                      style={{ width: "50%" }}
                      id="id_object"
                      label="Departamento"
                      name="id_object"
                      select
                      required
                      fullWidth
                      value={values.id_object}
                      onChange={handleChange}
                    >
                      {deparments.map((option: any) => (
                        <MenuItem
                          className="text-capitalize"
                          key={option.id}
                          value={option.id}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </Field>
                    <Field
                      as={TextField}
                      id="position"
                      style={{ width: "50%" }}
                      label="posición"
                      name="position"
                      required
                      values={values.position}
                      type="number"
                      inputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                        min: 0,
                        max: 100,
                      }}
                      helperText="Ingresa el número correspondiente a su posición"
                    />
                    <Button
                      type="submit"
                      className="save_Button text-center p-0 w-25 d-flex justify-content-center"
                      color="primary"
                    >
                      Guardar
                    </Button>
                  </Form>
                )}
              </Formik>
            </>
          );
        }
      })()}
      {/* FIN DE FORMULARIO DE DEPARTAMENTO */}
    </>
  );
};
