import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ICompany, IDepartments, ITags } from "../interface/services";
import { getCompanyByID } from "../Services/CompanyService";
import { getAllDepartments } from "../Services/DepartamentService";
import { getAllTags } from "../Services/TagServices";
import { useIsMounted } from "./useIsMounted";
import { getDraftsOrgCharts } from "../Services/OrganizationChartService";

export function useOrganizationChart(params: any, loading: boolean) {
  const [isLoading, setIsLoading] = useState<boolean>(loading);
  const [drafts, setDrafts] = useState([]);
  const [departments, setDepartments] = useState<IDepartments[]>([]);
  const [tags, setTags] = useState<ITags[]>([]);
  const [company, setCompany] = useState<ICompany>({
    id: 0,
    name: "",
    description: null,
    location: null,
    position: null,
  });

  useEffect(() => {
    const promises = [];
    promises.push(getAllTags().then(setTags));
    promises.push(getAllDepartments().then(setDepartments));
    promises.push(getCompanyByID(params.company_id).then(setCompany));
    promises.push(getDraftsOrgCharts(params.company_id).then(setDrafts));
    Promise.all(promises)
      .then(() => setIsLoading(false))
      .catch((error) => {
        if (error.response.status === 404) setIsLoading(false);
        toast.error("Ha habido un error");
      });
  }, []);

  return {
    departments,
    company,
    tags,
    isLoading,
    drafts,
  };
}
