import React from "react";
import { useHistory } from "react-router-dom";

export const HeaderVisual = ({ title }: { title: string }) => {
  const history = useHistory();
  return (
    <header
      className="col-12"
      style={{ position: "relative", overflow: "hidden", height: "80px" }}
    >
      <div>
        <div className="degraded_white_container_header">
          <img
            alt="header-fondo"
            className="img_background"
            src="/Assets/img/fondo_promocion.jpg"
            height="100%"
          ></img>
        </div>{" "}
      </div>

      <div className="header">
        <div className="d-flex justify-content-between align-items-center h-100 w-100 position-relative">
          <div
            className="d-flex align-items-center w-100 h-100  "
            style={{
              zIndex: "20",
            }}
            onClick={() => history.push("/organigrama")}
          >
            <img className="logo" src="/Assets/img/LogoAedasSmall.svg"></img>
            <h1
              className="title_header_app cursor-pointer"
              style={{ fontWeight: "bold" }}
            >
              Organigrama y Directorio
            </h1>
          </div>
          <div className="d-flex align-items-center h-100 w-100 position-absolute justify-content-center">
            <h1 className="title_header" style={{ fontWeight: "bold" }}>
              {title}
            </h1>
          </div>
        </div>
      </div>
    </header>
  );
};
