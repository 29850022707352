import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { ITags } from "../../../../interface/services";
import { norepobj } from "../../../../Utils/filterDuplicates";
import { Field } from "formik";

export const TagRow = ({ formik, index, tags, employees }: any) => {
  let employeesOptions: any = [];
  employees.map((element: any, i: number) => {
    employeesOptions.push({
      key: i,
      value: element.display_name + " - " + element.azure_rol,
      label:
        element.display_name === "TBR"
          ? element.display_name + " - " + element.azure_rol
          : element.display_name,
      id: element.id,
    });
  });
  return (
    <div
      style={{
        marginTop: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "0.5rem",
        width: "100%",
      }}
    >
      <Autocomplete
        id="id_tag"
        fullWidth
        options={tags}
        value={formik.values.tags[index].id_tag}
        getOptionLabel={(tag: ITags) => tag.name}
        onChange={(_e, value) => {
          formik.setFieldValue(`tags[${index}].id_tag`, value);
        }}
        renderInput={(params) => (
          <TextField {...params} label="Tag" size="medium" />
        )}
      />
      <Autocomplete
        disablePortal
        id="id_manager"
        onChange={(_e, value) => {
          formik.setFieldValue(`tags[${index}].id_manager`, value);
        }}
        options={norepobj(employeesOptions)}
        value={formik.values.tags[index]?.id_manager}
        fullWidth
        renderOption={(props: any, option: any) => {
          return (
            <li {...props} key={option.key} value={option.id}>
              {option.label}
            </li>
          );
        }}
        renderInput={(params) => {
          return (
            <TextField style={{ width: "100%" }} {...params} label="Manager" />
          );
        }}
      />
      <TextField
        id="altura"
        fullWidth
        // disabled={formik.values.tags[index].assistant}
        label="Altura"
        type={"number"}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          formik.setFieldValue(`tags[${index}].altura`, event.target.value);
        }}
        inputProps={{
          inputMode: "numeric",
          pattern: "[0-9]*",
          min: 0,
          max: 3,
        }}
      />
      <FormControlLabel
        style={{ minWidth: "19%" }}
        control={
          <Checkbox
            id="assistant"
            checked={formik.values.assistant}
            onChange={(e, value) => {
              formik.setFieldValue(`tags[${index}].assistant`, value);
            }}
          />
        }
        label="¿Es su asistente?"
      ></FormControlLabel>
    </div>
  );
};
