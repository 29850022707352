import { useEffect, useState } from "react";
import { getAllDepartments } from "../../Services/DepartamentService";
import { getOrgDpartment } from "../../Services/Visual/visual";
import { IDepartments } from "../../interface/services";
import { getSortedChildren } from "../../Utils/orderChilds";
import { searchTree } from "../utils/SearchTree";

export function useOrgDepartment(params: any) {
  const [data, setData] = useState([]);
  const [departments, setDepartments] = useState<IDepartments[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (isLoading) {
      let aux = [];

      aux.push(
        getOrgDpartment(params.id)
          .then((res) => {
            if (params.employee !== null && params.employee !== undefined) {
              processData(res);
            } else {
              setData(res);
              setIsLoading(false);
            }
          })
          .catch((error) => {
            setError(true);
            setIsLoading(false);
          })
      );
      aux.push(getAllDepartments().then(setDepartments));
      Promise.all(aux)
        .then(() => setIsLoading(false))
        .catch(() => {
          setIsLoading(false);
        })
        .finally(() => setIsLoading(false));
    }
  }, [isLoading, params.id]);

  useEffect(() => {

    data && getSortedChildren(data);
  }, []);

  const processData = (info: any) => {
    searchTree(info, params.employee);
    setData(info);
    setIsLoading(false);
  };

  return {
    isLoading,
    error,
    data,
    departments,
  };
}
