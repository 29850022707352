import axios from "axios";
import { configAuth } from "../Utils/headersConfig";
import { toast } from "react-toastify";

export const getNewOrgCompany = async (id:number) => {
    return await axios
      .get(
        process.env.REACT_APP_API_BACKEND + "/api/v1" + "/organigramanew/new?id_company="+id,
        configAuth()
      )
      .then((res) => res.data)
      .catch((error) => {
        toast.error("Ha habido un problema, intentelo más tarde");
      });
  };
  

  
  export const getNewOrgDepartment = async (id_company:number, id_department: number) => {
    return await axios
      .get(
        process.env.REACT_APP_API_BACKEND + "/api/v1" + `/organigramanew/new/department/${id_department}?id_company=${id_company}`,
        configAuth()
      )
      .then((res) => res.data)
      .catch((error) => {
        toast.error("Ha habido un problema, intentelo más tarde");
      });
  };


  export const getNewOrgTag = async (id_company:number, id_tag: number) => {
    return await axios
      .get(
        process.env.REACT_APP_API_BACKEND + "/api/v1" + `/organigramanew/new/tag/${id_tag}?id_company=${id_company}`,
        configAuth()
      )
      .then((res) => res.data)
      .catch((error) => {
        toast.error("Ha habido un problema, intentelo más tarde");
      });
  };