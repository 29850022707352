import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  ICompany,
  IDelegation,
  IDepartments,
  IEmployee,
  IEmployeeTag,
  IEmployeeTeams,
  ITags,
} from "../interface/services";
import { getAllCompany } from "../Services/CompanyService";
import { getAllDelegations } from "../Services/DelegationService";
import { getAllDepartments } from "../Services/DepartamentService";
import { getAllEmployee } from "../Services/EmployeeConfig";
import { getAllEmployeeTags } from "../Services/EmployeeTagService";
import { getAllTags } from "../Services/TagServices";
import { getAllEmployeeTeams } from "../Services/TeamServices";

export function useEmployeesTable(update: boolean, companyId?: number | null) {
  const [isLoading, setIsLoading] = useState(true);
  const [employees, setEmployees] = useState<IEmployee[]>([]);
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const [delegations, setDelegation] = useState<IDelegation[]>([]);
  const [departments, setDepartaments] = useState<IDepartments[]>([]);
  const [teams, setAllTeams] = useState<IEmployeeTeams[]>([]);
  const [employeetags, setemployeetags] = useState<IEmployeeTag[]>([]);
  const [tags, setTags] = useState<ITags[]>([]);

  useEffect(() => {
    let promises = [];

    promises.push(getAllEmployee().then(setEmployees));
    promises.push(getAllCompany().then(setCompanies));
    promises.push(getAllDelegations().then(setDelegation));
    promises.push(getAllDepartments().then(setDepartaments));
    promises.push(getAllEmployeeTags().then(setemployeetags));
    promises.push(getAllTags().then(setTags));
    if (companyId) {
      promises.push(getAllEmployeeTeams(companyId!).then(setAllTeams));
    }

    Promise.all(promises)
      .then(() => setIsLoading(false))
      .catch((error) => {
        if (error.response.status === 404) {
          setIsLoading(false);
        } else {
          toast.error("Ha habido un error");
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update]);

  return {
    employees,
    setEmployees,
    delegations,
    companies,
    departments,
    employeetags,
    teams,
    tags,
    isLoading,
    setIsLoading,
  };
}
