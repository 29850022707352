import { Button } from "@mui/material";
import {
  GridColDef,
  GridRenderEditCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import moment from "moment";
import { SyntheticEvent } from "react";
import { ICompany, IDraftOrgChart } from "../../../../interface/services";
import {
  publishDraftDate,
  removeDraft,
} from "../../../../Services/OrganizationChartService";
import { toast } from "react-toastify";

export class TableHistory {
  constructor(
    public company: ICompany | null,
    public history: any,
    public setIsLoading: any
  ) {}

  columns: GridColDef[] = [
    {
      field: "id_company",
      headerName: "Empresa",
      type: "string",
      renderCell: () => {
        return <div>{this.company ? this.company.name : ""}</div>;
      },
      width: 200,
      editable: false,
      align: "left",
      flex: 1,
    },
    {
      field: "version",
      headerName: "Fecha",
      renderCell: (params: GridValueGetterParams) => {
        let date = moment(new Date(params.row.version)).format(
          "DD-MM-YYYY HH:mm"
        );
        return <div>{date}</div>;
      },
      type: "string",
      width: 200,
      editable: false,
      align: "left",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Acciones",
      width: 500,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <>
            <Button
              onClick={(e: SyntheticEvent) => {
                this.handleShowHistory(params);
              }}
              color="primary"
              className="org_Button"
            >
              Ver Organigrama
            </Button>
            <Button
              onClick={(e: SyntheticEvent) => {
                this.handleRemove(params.row.mongo_id);
              }}
              color="primary"
              className="org_Button"
            >
              Eliminar
            </Button>
            {/* <Button
              onClick={(e: SyntheticEvent) => {
                this.handlePublish(params.row.mongo_id, params.row.version);
              }}
              color="primary"
              className="org_Button"
            >
              Publicar
            </Button> */}
          </>
        );
      },
    },
  ];

  handleShowHistory = (params: any) => {
    const path = `${params.id}&version=${params.row.version}`;
    this.history.push(`/organigramas/historial/${this.company?.id}/${path}`);
  };

  handleRemove = (mongo_id: any) => {
    removeDraft(mongo_id, true);
    this.setIsLoading(true);
  };

  handleSave = (data: any) => {
    if (data.removed.length > 0) {
      this.handleRemove(data.removed);
    }
  };

  handlePublish = (mongo_id: string, version: string) => {
    publishDraftDate(mongo_id, version).then((resp: any) => {
      toast.success("Publicado correctamente");
    });
  };

  onCellClick = () => {
    return;
  };
}
