import { useEffect, useState } from "react";
import { IDepartments, ITags } from "../../interface/services";
import { getAllDepartments } from "../../Services/DepartamentService";
import { createOrder } from "../../Services/orderService";
import { getAllTags } from "../../Services/TagServices";

export function useModalOrders({ showModal }: any) {
  const [selection, setSelection] = useState(null);
  const [tags, setTags] = useState<ITags[]>([]);
  const [deparments, setDepartments] = useState<IDepartments[]>([]);
  const options = [
    {
      value: 0,
      label: "Departamento",
    },
    {
      value: 1,
      label: "Etiqueta",
    },
  ];

  useEffect(() => {
    getAllTags().then(setTags);
    getAllDepartments().then(setDepartments);
  }, []);
  const handleSelect = (e: any) => {
    setSelection(e.target.value);
  };
  const handleSubmit = (values: any) => {
    createOrder(values);
    showModal();
  };

  return {
    options,
    handleSelect,
    selection,
    tags,
    deparments,
    handleSubmit,
  };
}
