import { Button } from "@mui/material";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { SyntheticEvent } from "react";

export class TableOrgChart {
  constructor(public history: any) {}

  columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 9,
      hide: true,
      editable: false,
    },

    {
      field: "name",
      headerName: "Empresa",
      type: "string",
      width: 200,
      editable: true,
      align: "left",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Acciones",
      width: 500,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <>
            <Button
              onClick={(e: SyntheticEvent) =>
                this.handleRoute(params.row.id, e)
              }
              color="primary"
              className="org_Button organigrama-actual"
            >
              Organigrama
            </Button>
            <Button
              onClick={(e: SyntheticEvent) =>
                this.handleRoute(params.row.id, e)
              }
              color="primary"
              className="org_Button borradores"
            >
              Borradores
            </Button>
            <Button
              onClick={(e: SyntheticEvent) =>
                this.handleRoute(params.row.id, e)
              }
              color="primary"
              className="org_Button historial"
            >
              Historial
            </Button>
          </>
        );
      },
    },
  ];

  handleRoute = (companyID: number, e: any) => {
    const path = e.target.className.includes("organigrama-actual")
      ? "organigrama-actual"
      : e.target.className.includes("borradores")
      ? "borradores"
      : "historial";
    this.history.push(`/organigramas/${path}/${companyID}`);
  };
}
