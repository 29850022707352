import { useParams } from "react-router-dom";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import OrganizationChart from "@dabeng/react-orgchart";
import { AedasLoading } from "../../../Components/Shared/AedasLoading/AedasLoading";
import { OrgChartError } from "../shared/error/OrgChartError";
import { HeaderVisual } from "../shared/Header";
import { useOrgDepartment } from "../../hooks/useOrgDepartment";
import { Leyend } from "../shared/Leyend";
import { useEffect, useState } from "react";
import "./styles/Organigrama.css";
import { findPeopleInDt } from "../../../Utils/findInDt";
import { getNewOrgDepartment } from "../../../Services/NewOrgService";
import NewOrg from "../org/NewOrg";

export function OrgChartDepartment() {
  const params = useParams();
  const [leyend, setShowLeyend] = useState(false);
  const { departments, error } = useOrgDepartment(params);
  const TITLE_ORGCHART = departments.find((dep) => dep.id === +params.id)?.name;

  const [data, setData] = useState(null);
  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    setShowLeyend(findPeopleInDt(data));
  }, [data]);
  useEffect(() => {
    getNewOrgDepartment(1, params.id).then((res) => {
      setData(res);
      setisLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (isLoading) {
    return <AedasLoading />;
  }

  return (
    <>
      <HeaderVisual title={TITLE_ORGCHART}></HeaderVisual>
      {/* GESTION DE ERROR DE ORGANIGRAMA */}
      {error ? (
        <OrgChartError></OrgChartError>
      ) : (
        <>
          {leyend && <Leyend showLeyendDt={leyend} />}
          <div>
            <NewOrg data={data} idUser={params.employee}></NewOrg>
          </div>
        </>
      )}
    </>
  );
}
