export const userLogin = (
  user: any,
  token: string,
  position: string,
  finishLogin: boolean
) => {
  return {
    type: "@user/login",
    payload: { user: user, token: token, position: position, finishLogin },
  };
};

export const userLogout = () => {
  return {
    type: "@user/logout",
  };
};
