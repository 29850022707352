import axios from "axios";
import { toast } from "react-toastify";
import { configAuth } from "../Utils/headersConfig";

export const getAllEmployeeTeams = (id_company: number) => {
  return axios
    .get(
      process.env.REACT_APP_API_BACKEND +
        "/api/v1" +
        "/team/aux/?id_company=" +
        id_company,
      configAuth()
    )
    .then((res) => res.data)
    .catch((error) => toast.error("Ha habido un error"));
};
