/* eslint-disable eqeqeq */
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useOrgTag } from "../../hooks/useOrgTag";
import { AedasLoading } from "../../../Components/Shared/AedasLoading/AedasLoading";
import { OrgChartError } from "../shared/error/OrgChartError";
import { HeaderVisual } from "../shared/Header";
import { Leyend } from "../shared/Leyend";
import { findPeopleInDt } from "../../../Utils/findInDt";
import { getNewOrgTag } from "../../../Services/NewOrgService";
import NewOrg from "../org/NewOrg";

export function OrganigramaMainTag() {
  const params = useParams();
  const [leyend, setShowLeyend] = useState(false);
  const { employeeTags, error, tags } = useOrgTag(params);
  const TITLE_ORGCHART = tags.find((tag) => tag.id === +params.id)?.name;
  const [data, setData] = useState(null);
  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    setShowLeyend(findPeopleInDt(employeeTags));
  }, [employeeTags]);
  useEffect(() => {
    getNewOrgTag(1, params.id).then((res) => {
      setData(res);
      setisLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <AedasLoading />;
  }
  return (
    <>
      <HeaderVisual title={TITLE_ORGCHART}></HeaderVisual>
      {/* GESTION DE ERROR DE ORGANIGRAMA */}
      {error !== null && error !== undefined && error !== "" ? (
        <OrgChartError></OrgChartError>
      ) : (
        <>
          {employeeTags && (
            <Leyend
              showLeyendComite={true}
              showLeyendDt={leyend}
              //! {ÑAPA ID DIRECCION Y SENIOR -- IGUAL HACERLO CON EL TITLE_ORGCHART  ¿?}
              showComite={params.id == 1 || params.id == 2}
            />
          )}

          <div>
            <NewOrg
              data={data}
              idUser={params.employee}
              showComite={params.id == 1 || params.id == 2}
            ></NewOrg>
          </div>
        </>
      )}
    </>
  );
}
