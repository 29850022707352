import { GridColDef, GridRenderEditCellParams } from "@mui/x-data-grid";
import { removeOrder, updateOrder } from "../../../../Services/orderService";
import { TYPE_ORDER } from "../../../../Utils/typesCases";

export class TableOrder {
  constructor(public orders: any) {}
  columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 9,
      hide: true,
      editable: false,
    },
    {
      field: "name",
      headerName: "Nombre",
      width: 200,
      editable: true,
      flex: 1,
    },
    {
      field: "position",
      headerName: "Posicion",
      type: "number",
      width: 200,
      editable: true,
      flex: 1,
    },
    {
      field: "id_type",
      headerName: "Tipo",
      width: 200,
      editable: false,
      flex: 1,
      valueGetter: (params: GridRenderEditCellParams) => {
        const state = TYPE_ORDER[params.row.id_type];
        return state;
      },
    },
  ];

  handleUpdate = (orders: any) => {
    orders.map(async (order: any) => {
      const orderUpdated = {
        id: order.id,
        position: +order.position,
        id_type: order.id_type,
        id_object: order.id_object,
      };
      updateOrder(order.id, orderUpdated).catch((error) => console.log(error));
    });
  };

  handleRemove = (orders: any) => {
    orders.map((order: any) => {
      removeOrder(order.id).catch((error) => console.log(error));
    });
  };

  handleSave = (data: any) => {
    if (data.edited.length > 0) this.handleUpdate(data.edited);
    if (data.removed.length > 0) this.handleRemove(data.removed);
  };
}
