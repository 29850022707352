import axios from "axios";
import { toast } from "react-toastify";
import { configAuth } from "../../Utils/headersConfig";
import { IEmployeeInsightsHub } from "../../interface/services";

export const getOrgDpartment = async (id: any) => {
  return await axios
    .get(
      process.env.REACT_APP_API + "/organigrama/" + id + "?id_company=1",
      configAuth()
    )
    .then((res) => res.data);
};

export const getOrgByTag = async (id: any) => {
  return await axios
    .get(
      process.env.REACT_APP_API +
        "/organigrama/tag/?tag_id=" +
        id +
        "&id_company=1"
    )
    .then((res) => res.data);
};

export const getEmployeeTags = async (tag_id: any) => {
  return await axios
    .get(
      process.env.REACT_APP_API_BACKEND +
        "/api/v1" +
        "/organigrama/tag/employees/" +
        tag_id,
      configAuth()
    )
    .then((res) => res.data)
    .catch((error) => {
      toast.error("Ha habido un problema, intentelo más tarde");
    });
};

export const getBySearch = async (search: string) => {
  return await axios
    .get(
      process.env.REACT_APP_API_BACKEND +
        "/api/v1" +
        "/employee/busqueda/?termino=" +
        search,
      configAuth()
    )
    .catch((err) => console.log(err));
};

export const getEmployeeInsightsHub = async (
  id: number
): Promise<IEmployeeInsightsHub> => {
  return await axios
    .get(
      `${process.env.REACT_APP_API_BACKEND}/api/v1/employee/extra/?id_employee=${id}`,
      configAuth()
    )
    .then((res) => res.data)
    .catch((error) => {
      toast.error("Ha habido un problema, intentelo más tarde");
    });
};
