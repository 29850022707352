import { useState, useEffect } from "react";
import { DataGrid, GridColDef, GridToolbarContainer } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";

import "./TableNormal.css";

export function TableNormal(props: any) {
  const {
    rows,
    columns,
    setEdit,
    edit,
    singleIcon,
    onCellClick,
    getRowId,
    modifiedHeight,
  } = props;
  const [columsCustom, setColumsCustom] = useState<GridColDef[]>([]);
  const [columsRows, setRowsCustom] = useState([]);
  useEffect(() => {
    processData();
  }, [rows]);

  const processData = () => {
    setRowsCustom(rows);

    let array: GridColDef[] = [];
    let columsAux = JSON.parse(JSON.stringify(columns));

    columsAux.map((row: GridColDef, i: number) => {
      row.editable = false;
      if (columns[i].renderCell) {
        row.renderCell = columns[i].renderCell;
      }
      if (columns[i].valueGetter) {
        row.valueGetter = columns[i].valueGetter;
      }
      if (columns[i].renderEditCell) {
        row.renderEditCell = columns[i].renderEditCell;
      }

      array.push(row);
    });

    array.sort((a, b) => {
      return parseInt(a.field) < parseInt(b.field) ? -1 : 1;
    });

    setColumsCustom(array);
  };

  const handleEdit = () => {
    setEdit(true);
  };

  const CustomToolbar = () => {
    return (
      <>
        {edit ? (
          <GridToolbarContainer className="d-flex justify-content-end">
            <h1 className="titleTableNormal">{props.title}</h1>

            {singleIcon ? (
              <IconButton onClick={handleEdit}>
                <EditIcon />
              </IconButton>
            ) : (
              <>
                <IconButton onClick={onCellClick}>
                  <AddCircleOutlineIcon />
                </IconButton>
                <IconButton onClick={handleEdit}>
                  <DeleteIcon />
                </IconButton>
              </>
            )}
          </GridToolbarContainer>
        ) : (
          <></>
        )}
      </>
    );
  };
  let styleHeight = "100%";
  styleHeight = modifiedHeight ? "89%" : "100%";

  return (
    <div className="dataGrid-table" style={{ height: styleHeight }}>
      <DataGrid
        style={{ height: "100%" }}
        components={{ Toolbar: CustomToolbar }}
        rows={columsRows}
        columns={columsCustom}
        onCellClick={
          props.onCellClick === "function"
            ? (event: any) => {
                props.onCellClick(event);
              }
            : (event: any) => {
                props.openModalOnClickCell(event);
              }
        }
        disableSelectionOnClick={false}
        editMode="row"
        getRowId={getRowId}
      ></DataGrid>
    </div>
  );
}
