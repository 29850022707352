import { useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ICompany } from "../../interface/services";
import { getAllCompany } from "../../Services/CompanyService";
import { getAllOrders } from "../../Services/orderService";
import { getBySearch } from "../../Services/Visual/visual";
import { useSelector } from "react-redux";

export function useTabs() {
  function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  const [search, setSearch] = useState(query.get("search") ?? "");
  const [isLoading, setIsLoading] = useState(true);
  const [withoutResults, setWithoutResults] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const [AllData, setAllData] = useState([]);
  const finishLogin = useSelector(
    (state: any) => state?.userReducer?.finishLogin
  );
  const history = useHistory();
  const isMounted = useRef(false);
  useEffect(() => {
    finishLogin && getAllOrders().then(setAllData);
  }, [finishLogin]);

  useEffect(() => {
    const SEARCH = query.get("search");
    if (isLoading) {
      if (SEARCH) {
        getBySearch(SEARCH)
          .then((response: any) => {
            setSearchResult(response.data);
            setIsLoading(false);
            setWithoutResults(false);
          })
          .catch((error) => {
            setWithoutResults(true);
            setIsLoading(false);
          });
      } else {
        if (finishLogin) {
          getAllCompany()
            .then((res: ICompany[]) => {
              setCompanies(res);
              setIsLoading(false);
              setWithoutResults(false);
            })
            .catch((error) => {
              setIsLoading(false);
              if ((error.status.code = 404)) {
                setWithoutResults(true);
              }
            });
        }
      }
    }
  }, [isLoading, finishLogin]);

  useEffect(() => {
    if (isMounted.current) {
      setIsLoading(true);
    } else {
      isMounted.current = true;
    }
  }, [query.get("search")]);

  const handleChange = (e: any) => {
    setSearch(e.target.value);
  };
  const _handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };
  const handleSearch = () => {
    history.push("/organigrama?search=" + search);
  };
  return {
    isLoading,
    history,
    _handleKeyDown,
    search,
    handleChange,
    handleSearch,
    query,
    withoutResults,
    searchResult,
    AllData,
    companies,
  };
}
