export const norepobj = (arr: any[]) => {
  let unique: any[] = [];
  let list = arr.filter((el: any) => {
    let isDup = unique.includes(el.value);

    if (!isDup) {
      unique.push(el.value);
      return true;
    }
  });
  return list;
};
