import {
  GridColDef,
  GridRenderEditCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { toast } from "react-toastify";
import { ITags } from "../../../../interface/services";
import Button from "@mui/material/Button";

import {
  createTag,
  removeTag,
  updateTag,
} from "../../../../Services/TagServices";
import { schema } from "./Tags";
import CheckBox from "@mui/material/Checkbox";

export class TableTags {
  constructor(public setIsLoading: any) {}

  columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 9, hide: true, editable: true },
    {
      field: "name",
      headerName: "Nombre",
      type: "string",
      width: 200,
      editable: true,
      flex: 1,
    },

    {
      field: "description",
      headerName: "Descripción",
      width: 200,
      editable: true,
      flex: 1,
    },
    {
      field: "is_dep",
      headerName: "Departamento",
      type: "boolean",
      width: 140,
      editable: true,
    },
    {
      field: "actions",
      headerName: "Acciones",
      renderCell: (params: GridValueGetterParams) => {
        return (
          <>
            <Button color="primary" className="org_Button">
              Gestión de Empleados
            </Button>
          </>
        );
      },
      width: 200,
      flex: 1,
    },
  ];

  handleCreate = (tags: ITags[]) => {
    tags.map(async (tag: ITags) => {
      const result = await schema
        .isValid(tag)
        .catch((error) => toast.error("La empresa no puede estar vacia"));
      if (result) {
        let id: number = tag.id;
        let name: string = tag.name;
        let description = tag.description;
        let is_dep = tag.is_dep;
        createTag(id, name, description, is_dep)
          .then(() => this.setIsLoading(true))
          .catch((error) => console.log(error));
      }
    });
  };

  handleUpdate = (tags: ITags[]) => {
    tags.map(async (tag: ITags) => {
      const result = await schema
        .isValid(tag)
        .catch((error) => toast.error("La empresa no puede estar vacia"));
      if (result) {
        updateTag(tag)
          .then(() => this.setIsLoading(true))
          .catch((error) => console.log(error));
      }
    });
  };

  handleRemove = (tags: ITags[]) => {
    tags.map(async (tag: ITags) => {
      const result = await schema
        .isValid(tag)
        .catch((error) => toast.error("La empresa no puede estar vacia"));
      if (result) {
        removeTag(tag)
          .then(() => this.setIsLoading(true))
          .catch((error) => console.log(error));
      }
    });
  };

  handleSave = (data: any) => {
    if (data.news.length > 0) this.handleCreate(data.news);
    if (data.edited.length > 0) this.handleUpdate(data.edited);
    if (data.removed.length > 0) this.handleRemove(data.removed);
  };
}
